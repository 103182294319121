// @ts-check
import {t} from "i18next";
import {uniq} from "lodash";

import {ORCHESTRATOR} from "../../../config/api_config";
import {SELECTABLE_STATUS, STATUS_KEY} from "../../../config/op_status";
import {diffDateTime, getDateTimeFromISO, plusDT, setDateTime, startOf} from "../../utils/luxon_helpers";
import {sortArrayOfObjectsByKey} from "../../utils/sort_array_of_objects_by_key";
import sortLocation from "../../utils/sort_location";

// Importing from the op_edit_layer broke the unit tests, so defined the constant here
const CONFLICT = "Conflict";
const DIVIDER = "divider";
const SUBHEADER = "subheader";

export const RHF_EDIT_LAYER_NAMES = Object.freeze({
    status: "status",
    isLocked: "isLocked",
    location: "location",
    dateStart: "dateStart",
    startOfToday: "startOfToday", // in order to set minimum date in dateStart
    duration: "duration", // in seconds
    timeStart: "timeStart",
    timeEnd: "timeEnd",
    isIndividual: "isIndividual", // in oder to check required fields of surgeon1, mentor1 or surgeryApprentice1
    surgeon1: "surgeon1",
    surgeryApprentice1: "surgeryApprentice1",
    mentor1: "mentor1",
    surgeon2: "surgeon2",
    otherSurgeons: "otherSurgeons",
    anesthesias: "anesthesias",
    anesthesiaNurses: "anesthesiaNurses",
    surgeryNurses: "surgeryNurses",
    cardiacPerfusionist: "cardiacPerfusionist",
    reasonForChange: "reasonForChange",
    notesForReason: "notesForReason",
    surgeonPresenting: "surgeonPresenting",
    isNotAttending: "isNotAttending",
    medicalResponsible: "medicalResponsible",
    medicalClearance: "medicalClearance",
    comment: "comment"
});

/** @type Partial<NextOrOpTeamRole>[] */
const practitionersThatCanBeOnDemand = [
    RHF_EDIT_LAYER_NAMES.surgeon1,
    RHF_EDIT_LAYER_NAMES.surgeryApprentice1,
    RHF_EDIT_LAYER_NAMES.mentor1
];

export const participantTypes = Object.freeze({
    surgeon1: "surgeon1",
    surgeryApprentice1: "surgeryApprentice1",
    mentor1: "mentor1",
    surgeon2: "surgeon2"
});

export const fallbackPractitioners = "fallbackPractitioners";

/**
 * Build standard initial name from the first and last name
 *
 * @param {String} firstName
 * @param {String} lastName
 * @return {String}
 */
export const buildInitialNameFromUser = (firstName, lastName) => {
    return lastName + " " + firstName.charAt(0).toUpperCase() + ".";
};

/**
 * Build standard initial name from the users list and a practitionerId
 *
 * @param {Array<User>} users
 * @param {String} practitionerId
 * @return {String}
 */
export const buildUserNameFromPractitionerId = (users, practitionerId) => {
    const user = users.find((user) => user.practitionerId === practitionerId);
    return buildInitialNameFromUser(user.firstName, user.lastName);
};

/**
 *
 * @param {Practitioners[]} participants
 * @param {String[]} types
 * @param {Object<String, String>} names the object of key of practitionerId and value of initial name. ex) {"123": "Hahn J.", "456": "Feuerbach D."}
 * @return {Array<{label: String, value: String}>}
 */
export const createParticipantTypesOptions = (participants, types, names) => {
    const ids = [];
    if (participants) {
        participants
            .filter((participant) => types?.includes(participant.type))
            .forEach((participant) => ids.push(...participant.practitionerIds));
    }

    return uniq(ids)
        .map((id) => ({label: names[id] || t("App.unknown"), value: id})) // show unknown if privateData is not found
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));
};

/**
 * Build participants structure
 *
 * @param {FormEditOpData} formsData
 * @return {NextOrOpTeam}
 */
const buildTeam = (formsData) => {
    /** @type {NextOrOpTeam} */
    const team = {};

    const participantSelectorList = [
        {category: "surgeon", formName: "surgeon1", isMultiple: false},
        {category: "surgeryApprentice", formName: "surgeryApprentice1", isMultiple: false},
        {category: "mentor", formName: "mentor1", isMultiple: false},
        {category: "surgeon", formName: "surgeon2", isMultiple: false},
        {category: "surgeon", formName: "otherSurgeons", isMultiple: true, indexStart: 3},
        {category: "anesthesia", formName: "anesthesias", isMultiple: true, indexStart: 1},
        {category: "anesthesiaNurse", formName: "anesthesiaNurses", isMultiple: true, indexStart: 1},
        {category: "instrumentation", formName: "surgeryNurses", isMultiple: true, indexStart: 1},
        {category: "cardiacPerfusionist", formName: "cardiacPerfusionist", isMultiple: true, indexStart: 1}
    ];

    participantSelectorList.forEach((mapElement) => {
        // Check if a form has any values selected. i.e for the multiple selector, the form has length > 0, single selector, it has a string
        const hasFormData =
            (mapElement.isMultiple && formsData[RHF_EDIT_LAYER_NAMES[mapElement.formName]].length) ||
            (!mapElement.isMultiple && formsData[RHF_EDIT_LAYER_NAMES[mapElement.formName]]);
        if (hasFormData) {
            if (!team[mapElement.category]) {
                team[mapElement.category] = {};
            }
            if (mapElement.isMultiple) {
                let i = mapElement.indexStart;
                formsData[RHF_EDIT_LAYER_NAMES[mapElement.formName]].forEach((participant) => {
                    const property = mapElement.category + i;
                    team[mapElement.category][property] = participant.value;
                    i = i + 1;
                });
            } else {
                team[mapElement.category] = {
                    ...team[mapElement.category],
                    [mapElement.formName]: formsData[RHF_EDIT_LAYER_NAMES[mapElement.formName]]
                };
            }
        }
    });

    return team;
};

/**
 * return first found date ISO string with refStart or refEnd is 0
 * @param {InternalTimestampsOpData} internalTimestamps
 * @return {String} date in ISO format
 */
const findRefZeroDate = (internalTimestamps) => {
    let date = null;
    for (const [, value] of Object.entries(internalTimestamps)) {
        if (value.refStart === 0) {
            date = value.dtStart;
            break;
        }
        if (value.refEnd === 0) {
            date = value.dtEnd;
            break;
        }
    }
    return date;
};

/**
 * calculate dtStart, dtEnd, refStart, refEnd from the baseDT, difference of baseDT, duration change
 *
 * @param {InternalTimestampItem} timestampItem
 * @param {DateTimeType} refBaseDT base date of ref is 0 currently duraRoomLockPre.dtStart
 * @param {Number} diffStartSeconds
 * @param {Number} duraChangedPercent
 * @param {String} isoFormat
 * @return {InternalTimestampItem | "Invalid DateTime"}
 */
const calcDateAndDuration = (timestampItem, refBaseDT, diffStartSeconds, duraChangedPercent, isoFormat) => {
    const newRefStart = Math.round(timestampItem.refStart * duraChangedPercent);
    const newRefEnd = Math.round(timestampItem.refEnd * duraChangedPercent);
    return {
        dtStart: plusDT(refBaseDT, "second", diffStartSeconds + newRefStart).toFormat(isoFormat),
        dtEnd: plusDT(refBaseDT, "second", diffStartSeconds + newRefEnd).toFormat(isoFormat),
        refStart: newRefStart,
        refEnd: newRefEnd
    };
};

/**
 * calculate dtStart, dtEnd, refStart, refEnd from the baseStart and configs of fixed percentage
 *
 * @comment formStatus is used to check if the formStatus in in ON_HOLD.
 * If the status is ON_HOLD, and the refBaseDT is invalid, set null for all the internalTimestamItems
 *
 * @param {DateTimeType} refBaseDT base date of ref is 0 currently duraRoomLockPre.dtStart
 * @param {Number} duration the total duration in seconds
 * @param {Number} startPercent
 * @param {Number} endPercent
 * @param {String} isoFormat
 * @param {string} formStatus
 * @return {InternalTimestampItem | "Invalid DateTime"}
 */
const calcDateAndDurationWithFixedPercentage = (refBaseDT, duration, startPercent, endPercent, isoFormat, formStatus) => {
    const newRefStart = Math.round((duration * startPercent) / 100);
    const newRefEnd = Math.round((duration * endPercent) / 100);

    const isOnHoldAndInvalidDate = formStatus === STATUS_KEY.ON_HOLD && !refBaseDT?.isValid;
    // emptyInternalTimestampItem
    return isOnHoldAndInvalidDate
        ? emptyInternalTimestampItem
        : {
              dtStart: plusDT(refBaseDT, "second", newRefStart).toFormat(isoFormat),
              dtEnd: plusDT(refBaseDT, "second", newRefEnd).toFormat(isoFormat),
              refStart: newRefStart,
              refEnd: newRefEnd
          };
};
const emptyInternalTimestampItem = {dtStart: null, dtEnd: null, refStart: 0, refEnd: 0};
const emptyInternalTimestamps = {
    duraRoomLockPre: emptyInternalTimestampItem,
    duraRoomLockOp: emptyInternalTimestampItem,
    duraRoomLockPost: emptyInternalTimestampItem,
    duraSurgeon1Lock: emptyInternalTimestampItem
};

/**
 * Calculate the durations of the duraRoomLockPre, duraRoomLockOp, duraRoomLockPost and duraSurgeon1Lock
 * by only changing the duration of the duraRoomLockOp and duraSurgeon1Lock,
 * and keeping the duraRoomLockPre and duraRoomLockPost as they are.
 *
 * @comment This works under the assumption that duraSurgeon1Lock encapsulates duraRoomLockOp
 * See ticket #23564 for more details
 *
 * @comment formStatus is used to check if the formStatus in in ON_HOLD.
 * If the status is ON_HOLD, and the start date is invalid, set null for all the internalTimestamItems
 * @see {@link https://dev.azure.com/next-data-service/13394-nextOR/_backlogs/backlog/Development%20Team/Features?showParents=true&workitem=26808}
 *
 * @param {InternalTimestampsOpData} internalTimestamps
 * @param {DateTimeType} newStartTime The new start time of the duraRoomLockPre
 * @param {String} isoFormat The format of the ISO string
 * @param {number} newDuration The new duration of the duraRoomLock in seconds
 * @param {string} formStatus The status of the form
 * @return {InternalTimestampsOpData}
 */
const calculateDurationsWithFixPreAndPost = (
    {duraRoomLockOp, duraRoomLockPre, duraSurgeon1Lock},
    newStartTime,
    isoFormat,
    newDuration,
    formStatus
) => {
    /**
     * This is hardcoded because DHM wants to have a fixed duration of 30 min for the duraRoomLockPre and duraRoomLockPost :(.
     */
    const durationPre = 30 * 60; // 30min * 60sec
    const durationPost = 30 * 60; // 30min * 60sec
    // const durationPre = duraRoomLockPre.refEnd - duraRoomLockPre.refStart;
    // const durationPost = duraRoomLockPost.refEnd - duraRoomLockPost.refStart;

    const durationX = duraRoomLockOp.refStart - duraSurgeon1Lock.refStart; // This is duration in which the surgeon1 is locked before the SNZ start time
    const durationY = duraSurgeon1Lock.refEnd - duraRoomLockOp.refEnd; // This is duration in which the surgeon1 is locked after the SNZ end time
    const durationOpNew = newDuration - durationPre - durationPost;

    const isOnHoldAndInvalidDate = formStatus === STATUS_KEY.ON_HOLD && !newStartTime.isValid;
    return isOnHoldAndInvalidDate
        ? emptyInternalTimestamps
        : {
              duraRoomLockPre: {
                  dtStart: newStartTime.toFormat(isoFormat),
                  dtEnd: plusDT(newStartTime, "second", durationPre).toFormat(isoFormat),
                  refStart: duraRoomLockPre.refStart, // It should be 0 always
                  refEnd: durationPre
              },
              duraRoomLockOp: {
                  dtStart: plusDT(newStartTime, "second", durationPre).toFormat(isoFormat),
                  dtEnd: plusDT(newStartTime, "second", durationPre + durationOpNew).toFormat(isoFormat),
                  refStart: durationPre,
                  refEnd: durationPre + durationOpNew
              },
              duraRoomLockPost: {
                  dtStart: plusDT(newStartTime, "second", durationPre + durationOpNew).toFormat(isoFormat),
                  dtEnd: plusDT(newStartTime, "second", newDuration).toFormat(isoFormat),
                  refStart: durationPre + durationOpNew,
                  refEnd: newDuration
              },
              duraSurgeon1Lock: {
                  dtStart: plusDT(newStartTime, "second", durationPre - durationX).toFormat(isoFormat),
                  dtEnd: plusDT(newStartTime, "second", durationPre + durationOpNew + durationY).toFormat(isoFormat),
                  refStart: durationPre - durationX,
                  refEnd: durationPre + durationOpNew + durationY
              }
          };
};

const timestampConfigMap = {
    duraRoomLockPre: {startPercent: 0, endPercent: 20},
    duraRoomLockOp: {startPercent: 20, endPercent: 80},
    duraSurgeon1Lock: {startPercent: 20, endPercent: 80},
    duraRoomLockPost: {startPercent: 80, endPercent: 100}
};

/**
 * recalculate internalTimestamps based on the existing timestamps, the new start date and duration from the user input
 *
 * @param {InternalTimestampsOpData} internalTimestamps
 * @param {number} newDuration
 * @param {DateTimeType} newStartTime
 * @param {String} isoFormat
 * @param {string} formStatus
 * @return {InternalTimestampsOpData} internalTimestamps
 * @link https://next-data-service.visualstudio.com/13394-nextOR/_wiki/wikis/13394-NextOR-Wiki/563/Timestamps-Durations
 * @link https://next-data-service.visualstudio.com/13394-nextOR/_wiki/wikis/13394-NextOR-Wiki/419/Timestamp-Handling
 */
export const recalculateInternalTimestampsFromTheStartAndDuration = (
    internalTimestamps,
    newDuration,
    newStartTime,
    isoFormat,
    formStatus
) => {
    /** @type InternalTimestampsOpData */
    const newTimestamps = {};
    const timestampProperties = Object.keys(timestampConfigMap);

    const hasAllTimestamps = timestampProperties.every(
        (key) => typeof internalTimestamps[key]?.refStart === "number" && typeof internalTimestamps[key]?.refEnd === "number"
    );

    // Check if all the properties are set, if not calculate with the fixed percentage
    if (hasAllTimestamps) {
        const refZeroDate = findRefZeroDate(internalTimestamps);
        const refBaseDT = refZeroDate ? getDateTimeFromISO(refZeroDate) : newStartTime;
        const diffStartSeconds = diffDateTime(newStartTime, getDateTimeFromISO(internalTimestamps.duraRoomLockPre.dtStart), "seconds") || 0;
        const duraChangedPercent = newDuration / internalTimestamps.duraRoomLockPost.refEnd;
        const isNewDurationLessThan70Min = newDuration < 4200; // 70min * 60sec

        if (isNewDurationLessThan70Min) {
            timestampProperties.forEach((key) => {
                newTimestamps[key] = calcDateAndDuration(
                    internalTimestamps[key],
                    refBaseDT,
                    Math.round(diffStartSeconds),
                    duraChangedPercent,
                    isoFormat
                );
            });
            return newTimestamps;
        }

        return calculateDurationsWithFixPreAndPost(internalTimestamps, newStartTime, isoFormat, newDuration, formStatus);
    }

    Object.entries(timestampConfigMap).forEach(([key, config]) => {
        newTimestamps[key] = calcDateAndDurationWithFixedPercentage(
            newStartTime,
            newDuration,
            config.startPercent,
            config.endPercent,
            isoFormat,
            formStatus
        );
    });

    return newTimestamps;
};

/**
 * generate payload for the save action
 * @param {FormEditOpData} formsData
 * @param {Object} param1
 * @param {Object} param2
 * @return {PatchEditOpData}
 */
export const generatePayloadForSave = (
    formsData,
    {id, personId, hcServiceId, internalTimestamps, prio, error},
    {isoFormat, fromJSDate, setDT, getDT}
) => {
    const participants = buildTeam(formsData);

    const dateStartDT = fromJSDate(formsData[RHF_EDIT_LAYER_NAMES.dateStart]);
    const newStartTime = setDT(fromJSDate(formsData[RHF_EDIT_LAYER_NAMES.timeStart]), {
        year: getDT(dateStartDT, "year"),
        month: getDT(dateStartDT, "month"),
        day: getDT(dateStartDT, "day")
    });

    const medClearance = formsData[RHF_EDIT_LAYER_NAMES.medicalClearance];
    const medResponsible = formsData[RHF_EDIT_LAYER_NAMES.medicalResponsible];
    const surgeonPresenting = formsData[RHF_EDIT_LAYER_NAMES.surgeonPresenting];
    const isAttending = !formsData[RHF_EDIT_LAYER_NAMES.isNotAttending];
    const formStatus = formsData[RHF_EDIT_LAYER_NAMES.status];

    /** @type PatchManualChange */
    const manualChange = {
        id,
        personId,
        location: formsData[RHF_EDIT_LAYER_NAMES.location],
        reasonForChange: formsData[RHF_EDIT_LAYER_NAMES.reasonForChange].map((el) => el.value) || [],
        status: ORCHESTRATOR.STATUS_MAPPING_MANUAL_CHANGE[formsData[RHF_EDIT_LAYER_NAMES.status]],
        participants,
        commentsReasonForChange: formsData[RHF_EDIT_LAYER_NAMES.notesForReason],
        next_medClearance: medClearance
            ? {
                  reference: medClearance,
                  type: "Practitioner"
              }
            : null,
        next_medResponsible: medResponsible
            ? {
                  reference: medResponsible,
                  type: "Practitioner"
              }
            : null,
        next_surgeonPresenting: surgeonPresenting
            ? {
                  reference: surgeonPresenting,
                  type: "Practitioner",
                  isAttending: isAttending
              }
            : null,
        healthcareService: hcServiceId,
        internalTimestamps: recalculateInternalTimestampsFromTheStartAndDuration(
            internalTimestamps,
            Math.round(Number(formsData[RHF_EDIT_LAYER_NAMES.duration])),
            newStartTime,
            isoFormat,
            formStatus
        ),
        prio,
        comment: formsData[RHF_EDIT_LAYER_NAMES.comment],
        error
    };
    // organizationId, sessionId, email and opBacklog will be set in the action creator
    const payload = {
        manualChanges: [manualChange]
    };
    return payload;
};

/**
 * generate payload for the suggestion action
 *
 * @param {{id: string, healthcareService: string, sessionId: string, prio: number, personId: string, internalTimestamps: InternalTimestampsOpData}} info
 * @param {FormEditOpData} formValues
 * @param {{fromJSDate: FromJSDate, setDT: SetDate, getDT: Get, isoFormat: string}} dateFunctions
 * @param {boolean} hasLimit
 * @return {FetchSuggestionsParams}
 */
export const generateSuggestionsParams = (
    {id, healthcareService, sessionId, prio, personId, internalTimestamps},
    formValues,
    {fromJSDate, setDT, getDT, isoFormat},
    hasLimit
) => {
    const participants = buildTeam(formValues);
    const formStatus = formValues[RHF_EDIT_LAYER_NAMES.status];

    const dateStartDT = fromJSDate(formValues[RHF_EDIT_LAYER_NAMES.dateStart]);
    const newStartTime = setDT(fromJSDate(formValues[RHF_EDIT_LAYER_NAMES.timeStart]), {
        year: getDT(dateStartDT, "year"),
        month: getDT(dateStartDT, "month"),
        day: getDT(dateStartDT, "day")
    });
    return {
        sessionId,
        appointmentChange: {
            id,
            location: formValues[RHF_EDIT_LAYER_NAMES.location],
            internalTimestamps: recalculateInternalTimestampsFromTheStartAndDuration(
                internalTimestamps,
                Math.round(Number(formValues[RHF_EDIT_LAYER_NAMES.duration])),
                newStartTime,
                isoFormat,
                formStatus
            ),
            prio,
            personId,
            participants,
            healthcareService
        },
        hasLimit
    };
};
/**
 * return true if the surgery assignment has any assignment
 *
 * @param {Practitioners[]} procedureCodeParticipants
 * @param {NextOrOpTeamRole[]} surgeonTypes The participant types, e.g. ["surgeon1", "surgeryApprentice1", "mentor1"]
 * @return {Boolean}
 */
export const checkAssignment = (procedureCodeParticipants, surgeonTypes) =>
    procedureCodeParticipants
        .filter((participant) => surgeonTypes.includes(participant.type))
        ?.some((participant) => participant.practitionerIds?.length);

/**
 * Set the default value of the endTime
 * @param {string} dateEndISO The base hour and minutes (HH:mm) will be set from this ISO Stringthe end time to be set
 * @param {DateTimeType} nowDT The base date (YYYY-MM-DD) to be set to the endTime.
 * @param {number} diffSeconds The seconds to be added to the base HH:mm if it is not 0‚
 * @return {Date|null}
 */
export const setDefaultEndTime = (dateEndISO, nowDT, diffSeconds) => {
    // If the dateEndISO is not set, return empty string
    if (!dateEndISO) {
        return null;
    }

    // Prepare dateEnd
    const dateEndDT = getDateTimeFromISO(dateEndISO);
    const dateEndOfToday = setDateTime(startOf(nowDT, "day"), {
        hour: dateEndDT.hour,
        minute: dateEndDT.minute,
        second: dateEndDT.second
    });

    // If there is diffSeconds, return the re-calculated new endTime
    if (diffSeconds !== 0) {
        return plusDT(dateEndOfToday, "second", diffSeconds).toJSDate();
    }

    // Else return the Date
    return dateEndOfToday.toJSDate();
};

// This constant is for the options of the cardiac perfusionist pull down
// All other categories matches to the group in the op-box and options in the edit layer.
// But the cardiac perfusionist is different between op-box and options in the edit layer. So I need to set options separately from the participantCategories
const CARDIAC_PERFUSIONIST = "cardiacPerfusionist";

/**
 * format options for the edit layer
 *
 * @param {Object} params
 * @param {Array<{name: string, id: string}>} params.roomInfos
 * @param {Object<string, {label: string, order: Number}>} params.locationInfos
 * @param {User[]} params.medOpManagers
 * @param {Array<string>} params.reasonsForChange
 * @param {Array<string>} params.surgeonIds
 * @param {Object<string, string>} params.allPractitionerNames
 * @param {boolean} params.isEnabled
 * @param {boolean} params.hasNoAssignment Whether the customer has enabled surgery assignment and the surgery has any assignments
 * @param {ParticipantCategoriesSettings} params.participantCategories A map of the participant categories e.g. ["surgeon", "anesthetist", "mentor", "assistant"]
 * @param {Array<{id: string, hcServiceId: string}>} params.onDemandPractitioners Practitioners that are on demand, e.g: "OnCall1" or "StandBy1" or "pediatricOnCall1"
 * @param {Practitioners[]} params.procedureCodeParticipants The participants allowed to perform an operation based on the procedure code
 * @param {Practitioners[]} params.pracRoleCategoryParticipants
 * @param {OpInfo} params.opInfo
 * @return {EditLayerOptions}
 */
export const formatOptions = ({
    roomInfos,
    locationInfos,
    medOpManagers,
    reasonsForChange,
    surgeonIds,
    allPractitionerNames,
    isEnabled,
    hasNoAssignment,
    participantCategories,
    procedureCodeParticipants,
    onDemandPractitioners,
    pracRoleCategoryParticipants,
    opInfo
}) => {
    /** @type EditLayerOptions */
    const options = {};
    options.statusOptions = SELECTABLE_STATUS.map((statusKey) => ({label: t(`Status.${statusKey}`), value: statusKey}));
    options.roomOptions = roomInfos
        .map((room) => ({
            label: room.name,
            value: room.id
        }))
        .sort(sortLocation(locationInfos, "value"));

    options.medicalOpManagerOptions = medOpManagers
        .filter(({practitionerId}) => practitionerId && practitionerId !== "none")
        .map((user) => ({label: buildInitialNameFromUser(user.firstName, user.lastName), value: user.practitionerId}))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

    options.reasonForChange = reasonsForChange
        .map((reason) => ({label: t(`ReasonForChange.${reason}`), value: reason}))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));

    // Prepare all surgeons from the medicalProfessionalGroup and the discipline
    const surgeonOptions = surgeonIds
        .map((id) => ({label: allPractitionerNames[id] || t("App.unknown"), value: id}))
        .sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"));
    options.surgeon2 = surgeonOptions;
    options.otherSurgeons = surgeonOptions;
    options.surgeonsPresenting = surgeonOptions;
    options.medicalResponsible = surgeonOptions;

    // Build participant options
    /** @type object<string, {source: "procedureCode"|"pracRole", typeName: NextOrOpTeamRole}> */
    const optionsMap = {
        [RHF_EDIT_LAYER_NAMES.surgeon1]:
            isEnabled && hasNoAssignment
                ? {source: "procedureCode", typeName: [fallbackPractitioners]}
                : isEnabled
                ? {source: "procedureCode", typeName: [RHF_EDIT_LAYER_NAMES.surgeon1]}
                : {source: "pracRole", typeName: participantCategories.surgeons},
        [RHF_EDIT_LAYER_NAMES.surgeryApprentice1]: isEnabled
            ? hasNoAssignment
                ? {source: "procedureCode", typeName: [fallbackPractitioners]}
                : {source: "procedureCode", typeName: [RHF_EDIT_LAYER_NAMES.surgeryApprentice1]}
            : null,
        [RHF_EDIT_LAYER_NAMES.mentor1]: isEnabled
            ? hasNoAssignment
                ? {source: "procedureCode", typeName: [fallbackPractitioners]}
                : {source: "procedureCode", typeName: [RHF_EDIT_LAYER_NAMES.mentor1]}
            : null,
        [RHF_EDIT_LAYER_NAMES.anesthesias]: {source: "pracRole", typeName: participantCategories.anesthesias},
        [RHF_EDIT_LAYER_NAMES.anesthesiaNurses]: {source: "pracRole", typeName: participantCategories.anesthesiaNurses},
        [RHF_EDIT_LAYER_NAMES.surgeryNurses]: {source: "pracRole", typeName: participantCategories.surgeryNurses},
        [RHF_EDIT_LAYER_NAMES.cardiacPerfusionist]: {source: "pracRole", typeName: [CARDIAC_PERFUSIONIST]}
    };

    for (const [key, value] of Object.entries(optionsMap)) {
        const source = value?.source === "procedureCode" ? procedureCodeParticipants : pracRoleCategoryParticipants;
        options[key] = value ? createParticipantTypesOptions(source, value.typeName, allPractitionerNames) : [];

        // Add on demand practitioners to the options of the participants
        if (practitionersThatCanBeOnDemand.includes(value.typeName?.[0])) {
            options[key] = addOnDemandPractitioners(options[key], onDemandPractitioners, allPractitionerNames, opInfo.hcServiceId);
        }
    }
    return options;
};

/**
 * a function to format the given surgeon1, surgeryApprentice1 and mentor1
 * if the surgeon1 is set, show the surgeon1
 * if the surgeon1 is not set, show the surgeryApprentice1 + mentor1
 * @param {string} surgeon1 the name of the surgeon1
 * @param {string} surgeryApprentice1 the name of the surgeryApprentice1
 * @param {string} mentor1 the name of the mentor1
 * @return {string}
 */
export const formatFirstSurgeon = (surgeon1, surgeryApprentice1, mentor1) =>
    surgeon1 || [surgeryApprentice1, mentor1].filter(Boolean).join(" + ");

/**
 * Assign unique id to suggestions
 *
 * @param {Array<Suggestion>} suggestions
 * @return {Array<Suggestion>}
 */
export const assignUniqueId = (suggestions) => {
    return suggestions.map((suggestion) => ({...suggestion, id: JSON.stringify(suggestion)}));
};

/** @type {Array<string>} */
const OPTIMIZATION_ITEMS = [
    RHF_EDIT_LAYER_NAMES.location,
    RHF_EDIT_LAYER_NAMES.dateStart,
    RHF_EDIT_LAYER_NAMES.timeStart,
    RHF_EDIT_LAYER_NAMES.timeEnd,
    RHF_EDIT_LAYER_NAMES.surgeon1,
    RHF_EDIT_LAYER_NAMES.mentor1,
    RHF_EDIT_LAYER_NAMES.surgeryApprentice1
];

/**
 * set the validate flag from the items changed
 * we validate if the optimization items are changed
 * Optimization items are two types:
 *   - for the dateStart, timeStart and timeEnd, we compare the original and new values in the format of "YYYY-MM-DD HH:mm" to ignore the seconds
 *   - for the other fields, we compare the dirtyFieldsList
 *
 * @param {Array<string>} dirtyFieldsList
 * @param {{originalStart: string, originalEnd: string}} original in the format of "YYYY-MM-DD HH:mm"
 * @param {{newStart: string, newEnd: string}} newValues in the format of "YYYY-MM-DD HH:mm"
 * @return {boolean}
 */
export const isOptimizationItemsChanged = (dirtyFieldsList, {originalStart, originalEnd}, {newStart, newEnd}) => {
    return (
        dirtyFieldsList.some((dirtyField) => OPTIMIZATION_ITEMS.includes(dirtyField)) ||
        originalStart !== newStart ||
        originalEnd !== newEnd
    );
};

/**
 * Sort suggestions by start
 *
 * @param {Suggestion} a
 * @param {Suggestion} b
 * @return {number}
 */
export const sortSuggestions = (a, b) => (a.start < b.start ? -1 : 1);

/**
 * Check if a user doesn't change any of the conflict items
 *
 * @param {FieldErrors} errors from the OpEditForm
 * @param {boolean} isIndividual if true, surgeon1. if false mentor1 and surgeryApprentice1 are the conflict item(s)
 * @return {boolean}
 */
export const areAllTheConflictErrorsUntouched = (errors, isIndividual) => {
    // filter all conflict errors
    const conflictErrors = Object.entries(errors)
        .filter(([_, error]) => error.type === CONFLICT)
        .map(([item, _]) => item);

    // array of all conflict items
    const allOptimazationItem = isIndividual
        ? OPTIMIZATION_ITEMS.filter((item) => item !== RHF_EDIT_LAYER_NAMES.mentor1 && item !== RHF_EDIT_LAYER_NAMES.surgeryApprentice1)
        : OPTIMIZATION_ITEMS.filter((item) => item !== RHF_EDIT_LAYER_NAMES.surgeon1);
    return allOptimazationItem.every((item) => conflictErrors.includes(item));
};

/**
 * Add on demand practitioners to the options of the participants and
 * format the options for the edit layer
 *
 * @param {Array<{value: string,label: string}>} optionKey
 * @param {Array<{id: string, hcServiceId: string}>} onDemandPractitioners
 * @param {Object<string, string>} names the object of key of practitionerId and value of initial name. ex) {"123": "Hahn J.", "456": "Feuerbach D."}
 * @param {string} hcServiceId
 * @return {Array<{value: string, label: string}>}
 */
const addOnDemandPractitioners = (optionKey, onDemandPractitioners, names, hcServiceId) => {
    const onDemandForHCService = onDemandPractitioners?.filter((prac) => prac.hcServiceId === hcServiceId) || [];
    return [
        {value: SUBHEADER, label: t("OpEditLayer.surgeonsSurgeryAssignment")},
        ...optionKey,
        {value: DIVIDER, label: DIVIDER},
        {value: SUBHEADER, label: t("OpEditLayer.surgeonsOnDemand")},
        ...onDemandForHCService.map(({id}) => ({label: names[id], value: id})).sort((a, b) => sortArrayOfObjectsByKey(a, b, "label"))
    ];
};
